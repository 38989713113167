import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra </h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">About Libra</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Sanskrit/Vedic name of Libra:</p> Tula<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Libra Meaning:</p> The Scales<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Type: </p>Air - Cardinal - Positive<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Libra Sign</p><br/>
Libra is the seventh sign and the only inanimate sign of the zodiac. The character epitomizes harmony and justice. The movement restores equilibrium to all affairs, no matter how big or small. The energy of the sign settles a major ethical conflict. It will stop at nothing to establish interpersonal and aesthetic unity.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How to recognize Libra?</p><br/>
Libra individuals are focused on their relationships and devoted to their partnerships. They are very good at prioritizing the need for an hour. They are very lovable to others, and they hate being rude or judgmental. There is the weird side of Libra; they don't like much crowded places and hate to be alone at the same time. They want peace, harmony, and freedom in their life. They know how to make people calm down in challenging situations and try to solve quarrels happening between two people. But they enjoy healthy arguments, proving their valid points, but don't want that conversation to change into a heated argument. 
 <br/><br/>
They want to go smooth in their life. They are also very level-headed and can therefore be sought after for practical solutions. However, they can stir up much controversy with their attitude and behavior. They are calm, perfectly balanced, sweet, gracious, and charming individuals and seldom rush or hurry. 
 <br/><br/>
Libr want space whenever they feel pressured or crowded. But when they are feeling low or down/upset, they can be irritating, argumentative, stubborn, restless, sad, and confused. But this does not last long because they know how to balance themselves and make things cool. Both Libra men and Libra women are fascinated by symmetry and balance. 
 <br/><br/>
However, they are in a continuous chase for fairness and equality. Their core qualities are genuinely vital for them, which they have realized through adventures in life.
 <br/><br/>
Libra individuals maintain constant mental stimuli, a powerful mind, and a strong mentality. They are magnetic, attractive, and positively sought after individuals. They can influence anyone and also attract the attention of many people. People are fond of them. Also, they are very joyful and loyal to their friends, families, and near and dear ones. So they often have a big friendly circle and many faithful friends. Hence, they are always trying to make others happy, making them feel special. Ready to struggle with anything for their loved ones. There are many other secret qualities to explore about Libra. Talk to our best astrologers! 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">All About Libra</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Date Range:</p>September 23 - October 22<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Domicile:</p> Venus <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Detriment:</p>mars <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Exaltation:</p>Saturn <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Fall:</p>the sun <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Air<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Quality:</p>Cardinal<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Symbol:</p>The Scales<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Tarot card:</p>Justice<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Fellow signs:</p>Gemini, Aquarius<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatible signs:</p> Gemini, Leo, Sagittarius, Aquarius<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Most Compatible Signs:</p>Gemini<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Opposite Signs:</p> Aries<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The Symbol denotes:</p>Harmony, justice, and a balanced nature<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Characteristics:</p> Airy, cardinal, masculine, positive, humane<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Noteworthy qualities:</p> Intelligent, unbiased, elegant, modest, flexible, persuasive, loving, cheerful, sympathetic, helpful, discreet, balanced.
 
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Undesirable personality traits:</p>Doubtful, extremist, reckless, impulsive, susceptible, aloof, tend to get carried away by others’ opinions.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Favorable things:</p>Live Concerts at large venues, poetry, expensive jewelry, designer clothes, rich food
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Things they hate:</p>Dull or sensible people, bullies, being pressured to decide, saying goodnight, hearing the word “maybe.”
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birthstone:</p>Diamond, white sapphire, and white topaz
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky stones:</p>white opal , rutilated quartz, blue tourmaline, moldavite
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky Metals:</p>Silver
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky day:</p>Friday
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky number:</p>6, 15, 24, 33, 42, 51, 60
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birth Colours:</p>All shades of pink, white, shades of blue, pale, and light green
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Most profound needs:</p>Love, romance, social harmony, and peace.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lifetime wish:</p> To love and be loved in return
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Mantra for life:</p>I have to maintain a balanced life with peace and harmony
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The quality most needed for balance:</p>A sense of self, self-reliance, independence
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
